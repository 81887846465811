import HorizontalDivider from "components/MyComponents/HorizontalDivider"
import { useTranslation } from "react-i18next"
import { Link, useHistory } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import { Unauth20Wrapper } from "../Unauth20Wrapper"
import { useSelector } from "react-redux"
import LoadingContent from "components/Common/LoadingContent"
import { ROUTES } from "routes"

interface IRegisterPageWrapper {
  showLoginLink?: boolean
  children: React.ReactNode
}

const RegisterPageWrapper = ({ children, showLoginLink = true }: IRegisterPageWrapper) => {

  const { t } = useTranslation();
  const loginState = useSelector((state: any) => state.LoginState);
  const history = useHistory();
  
  if (loginState.contractorId) {
    history.push(ROUTES.ROUTE_DASHBOARD);
    return <Container fluid><LoadingContent></LoadingContent></Container>
  }

  return (
    <Unauth20Wrapper isRegistrationPage={true}>
      <>
        <div className="form-title">                    
          <h2><span className="blue">{t("Registration.FirstPage.Header")} </span>
          </h2>
          <h6><span>{t("Registration.FirstPage.SubHeader")}</span></h6>
        </div>
        <Row>
          <Col sm={12} className="m-auto">
            {children}
          </Col>
        </Row>
        {showLoginLink && (
          <>
            <HorizontalDivider labelKey="separator.or" className="text-uppercase" />
            <Col xs={12} className="pt-2">
              <div className="signin-btn">
                <Link to="/login" >{t("Login.LogInNow")}</Link>
              </div>
            </Col>
          </>
        )}
      </>
    </Unauth20Wrapper>
  )
}

export default RegisterPageWrapper
